<template>
    <section class="xbts-changelog">


    </section>
</template>

<script>

export default {
    name: "Changelog",
}
</script>

<style scoped>

</style>
